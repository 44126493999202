import escapeRegExp from "escape-string-regexp";
import moment from "moment";
import {
  ADD_CAJA,
  DELETE_CAJA,
  SET_CURRENT_CAJA,
  CLEAR_CURRENT_CAJA,
  UPDATE_CAJA,
  FILTER_CAJA,
  CLEAR_FILTER_CAJA,
  ERROR_CAJA,
  GET_CAJA,
  CLEAR_CAJA,
  SET_OPEN_CAJA,
} from "../actions/types";

const initialState = {
  cajas: null,
  current: null,
  filtered: null,
  filter: {
    nombre: "",
    fechaLanzamiento: null,
    zonas: [],
    canales: [],
    vendedor: null,
  },
  error: null,
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CAJA:
      return {
        ...state,
        cajas: action.payload,
        loading: false,
      };
    case ADD_CAJA:
      return {
        ...state,
        cajas: [...state.cajas, action.payload],
        loading: false,
      };
    case UPDATE_CAJA:
      return {
        ...state,
        cajas: state.cajas.map((caja) =>
          caja.id === action.payload.id ? action.payload : caja
        ),
      };
    case DELETE_CAJA:
      return {
        ...state,
        cajas: state.cajas.filter((caja) => caja.id !== action.payload),
        loading: false,
      };
    case CLEAR_CAJA:
      return {
        ...state,
        cajas: null,
        error: null,
        current: null,
        loading: null,
      };
    case SET_CURRENT_CAJA:
      return {
        ...state,
        current: action.payload,
      };
    case SET_OPEN_CAJA:
      return {
        ...state,
        open: action.payload,
      };
    case CLEAR_CURRENT_CAJA:
      return {
        ...state,
        current: null,
      };

    case FILTER_CAJA:
      console.log("AAAAAAAAAAAAAAAAA", action.payload);
      return {
        ...state,
        filter: action.payload,
        filtered: state.cajas.filter((caja) => {
          let show = true;
          if (action.payload.nombre) {
            const regex = new RegExp(
              `${escapeRegExp(action.payload.nombre)}`,
              "gi"
            );
            show = show && caja.nombre.match(regex);
          }
          if (action.payload.fechaLanzamiento) {
          }
          return show;
        }),
      };
    case CLEAR_FILTER_CAJA:
      return {
        ...state,
        filtered: null,
      };
    case ERROR_CAJA:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
